import React, { useContext, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { InactivityTrackerContext } from "../../../Config/InactivityTracker";
import Button from "../Button/Button";
import "./InactivityWarning.scss";

const ONE_MINUTE = 60 * 1000;
const ONE_HOUR = ONE_MINUTE * 60;

function InactivityWarning(props) {
  const { intl, fixtureId } = props;
  const { formatMessage } = intl;
  const [isVisible, setVisible] = useState(false);
  const [timeLeft, setTimeLeft] = useState(-1);
  const [isReloadButtonVisible, setReloadButtonVisible] = useState(false);
  const { lastActionTime, setLastActionTime } = useContext(InactivityTrackerContext);

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      if (isVisible || !window.location.pathname.includes("game")) {
        return;
      }

      if (Date.now() - lastActionTime > ONE_HOUR) {
        setVisible(true);
        setTimeLeft(60);
      }
    }, ONE_MINUTE);

    return () => clearInterval(intervalId);
  }, [lastActionTime, isVisible]);

  useEffect(() => {
    if (isVisible) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isVisible]);

  useEffect(() => {
    if (timeLeft === 0) {
      // hard reload
      setReloadButtonVisible(true);
      return;
    }

    const timeoutId = setTimeout(() => {
      setTimeLeft((t) => t - 1);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [timeLeft, fixtureId]);

  const onReactivate = () => {
    setLastActionTime(Date.now());
    setVisible(false);
    setTimeLeft(-1);
  };

  const reloadPage = () => {
    window.location.pathname = `/game/${fixtureId}`;
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="warning-overlay">
      <div className="warning-modal">
        <h1>
          {isReloadButtonVisible
            ? formatMessage({
                id: "inactivity.messageReload",
                defaultMessage: "Due to inactivity, please reload the page",
              })
            : formatMessage({
                id: "inactivity.message",
                defaultMessage: "DUE TO INACTIVITY, YOU WILL BE FORCED TO RELOAD THIS PAGE IN ##seconds## SECONDS.",
              }).replace("##seconds##", timeLeft)}
        </h1>
        <div className="warning-modal-button">
          {isReloadButtonVisible ? (
            <Button variant="success" onClick={reloadPage}>
              {formatMessage({
                id: "inactivity.reload",
                defaultMessage: "Reload",
              })}
            </Button>
          ) : (
            <Button variant="danger" onClick={onReactivate}>
              {formatMessage({
                id: "inactivity.cancel",
                defaultMessage: "I am here",
              })}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default injectIntl(InactivityWarning);
