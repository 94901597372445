const createSuspensionEvent = (suspensionType) => [
  { action: "stopClock", value: "stop", type: "main" },

  { action: "saveData", value: "suspension", type: "eventType" },
  { action: "saveData", value: suspensionType, type: "subType" },
  {
    action: "saveData",
    value: [{ option: "possible", value: true }],
    type: "options",
  },
  { action: "sendEvent", value: "new", type: "main" },

  { action: "clearData", value: ["options"], type: "main" },
]

const createSuspensionClockEvent = () => [
  { action: "copyEvent", value: "suspension", type: "to" },
  { action: "newEventId", value: null, type: "main" },
  {
    action: "saveData",
    value: "clock",
    type: "class",
  },
  {
    action: "saveData",
    value: "suspension",
    type: "eventType",
  },
  {
    action: "saveData",
    value: "adjust",
    type: "subType",
  },
  {
    action: "saveData",
    value: [{ option: "value", value: "2" }],
    type: "options",
  },
  { action: "sendEvent", value: "new", type: "main" },
  { action: "copyEvent", value: "card-clock", type: "to" },
  { action: "copyEvent", value: "suspension", type: "from" },
];

export const SuspensionType = {
  name: "suspension-type",
  component: "MainPanel",
  layout: "main-panel large padded align-center",
  mode: "visible",
  text: "suspension.type",
  showOn: [{ state: "primary", value: "suspension-type", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  children: [
    {
      name: "suspension-buttons",
      component: "ButtonPanel",
      layout: "button-group centered",
      mode: "visible",
      showOn: [true],
      children: [
        {
          name: "2min-suspension",
          component: "TypeButton",
          layout: "type-button",
          mode: "enable",
          text: "suspension.2min",
          showOn: [true],
          actionGroups: [
            {
              actions: [
                ...createSuspensionEvent("twoMinutes"),
                ...createSuspensionClockEvent(),
                {
                  action: "nextState",
                  value: "suspension-player",
                  type: "primary",
                },
                { action: "processInserts", value: "all", type: "inserts" },
              ],
              conditions: [],
            },
          ],
          startEvent: true,
          startPlay: true,
        },
        {
          name: "4min-suspension",
          component: "TypeButton",
          layout: "type-button",
          mode: "enable",
          text: "suspension.4min",
          showOn: [true],
          actionGroups: [
            {
              actions: [
                ...createSuspensionEvent("fourMinutes"),
                ...createSuspensionClockEvent(),
                {
                  action: "nextState",
                  value: "suspension-player",
                  type: "primary",
                },
                { action: "processInserts", value: "all", type: "inserts" },
              ],
              conditions: [],
            },
          ],
          startEvent: true,
          startPlay: true,
        },
      ],
    },
  ],
};
