import { getDistanceBetweenTwoColors } from "../../../Base/Utilities/Color";
import { lightOrDark } from "../../../Base/Utilities/LightOrDark";

import "./PlayerFaultCounter.scss";

const redColor = "rgb(255, 0, 0)";
const MAX_PERSONAL_FOUL_PER_ROW = 6;

const PlayerFaultCounter = (props) => {
  const { maxFoulsPersonal, foulCount, personId, teamColor, isPlayerDisqualified } = props;

  function getFoulColor(teamColor) {
    const distance = getDistanceBetweenTwoColors(redColor, teamColor);
    const isDistanceAcceptable = distance > 350;

    return isDistanceAcceptable ? "red" : "yellow";
  }

  const foulColor = getFoulColor(teamColor);

  const getFoulBoxBackground = () => {
    if (isPlayerDisqualified) {
      return foulColor;
    }

    if (lightOrDark(teamColor) === "light") {
      return "#202020";
    }

    return "#fff";
  };

  return (
    <div
      className="foul-box-row"
      style={{
        gridTemplateColumns: `repeat(${Math.min(maxFoulsPersonal, MAX_PERSONAL_FOUL_PER_ROW)}, 0.3vw)`,
      }}
    >
      {Array.from(Array(Math.min(foulCount, maxFoulsPersonal))).map((_, idx) => (
        <>
          <div
            className={`foul-box${idx < foulCount ? " foul-box--ticked" : ""}`}
            key={`player-foul-${personId}-${idx}`}
            style={{
              backgroundColor: getFoulBoxBackground(),
            }}
          />
        </>
      ))}
    </div>
  );
};

export default PlayerFaultCounter;
