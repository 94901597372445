import { convertClock } from "../ConvertClock";

let lastProcessedEventId = "";

export async function handballClockCatchup(message, currentState, updateState, fixtureProfile) {
  let tempPeriod = currentState.period ? currentState.period : { periodId: 0 };
  let tempClock = currentState.clock ? JSON.parse(JSON.stringify(currentState.clock)) : {};

  message = message.sort(function (a, b) {
    var aMixed = new Date(a.eventTime.replace("Z", ""));
    var bMixed = new Date(b.eventTime.replace("Z", ""));
    return aMixed < bMixed || a.periodId < b.periodId ? -1 : 1;
  });

  const lastProcessedMessageIndex = lastProcessedEventId
    ? message.findIndex((event) => event.eventId === lastProcessedEventId)
    : 0;

  const messagesToProcess = message.slice(lastProcessedMessageIndex + 1);

  messagesToProcess.forEach((event) => {
    if (event.periodId >= tempPeriod.periodId) {
      tempPeriod.periodId = event.periodId;
    }

    const handlePeriodEvent = () => {
      tempPeriod.periodId = event.periodId;
      switch (event.subType) {
        case "start":
          tempPeriod.periodStatus = "inProgress";
          break;
        case "end":
          tempPeriod.periodStatus = "periodEnd";
          break;
        case "confirmed":
          if (tempPeriod.periodId >= fixtureProfile.numberOfPeriods) {
            tempPeriod.periodStatus = "pendingExtra";
          } else {
            tempPeriod.periodStatus = "pending";
            tempPeriod.periodId = tempPeriod.periodId + 1;
          }
          break;
        case "pending":
          if (event.periodId > 0) {
            tempPeriod.periodStatus = "pending";
            if (!event.clock) {
              if (event.periodId === 2) {
                tempClock.displayTime = convertClock("PT30M0S");
              } else {
                tempClock.displayTime = convertClock("PT0M0S");
              }
            }
          }
          break;
        default:
          break;
      }
    };

    const handleFixtureEvent = () => {
      switch (event.subType) {
        case "end":
          tempPeriod.periodStatus = "fixtureConfirm";
          break;
        case "confirmed":
          tempPeriod.periodStatus = "fixtureComplete";
          break;
        default:
          break;
      }
    };

    const handleClockMainEvent = () => {
      if (event.class === "clock") {
        tempClock.clockRunning = event.subType !== "stop";
      }
    };

    switch (event.eventType) {
      case "period":
        handlePeriodEvent();
        break;
      case "fixture":
        handleFixtureEvent();
        break;
      case "main":
        handleClockMainEvent();
        break;
      default:
        break;
    }

    if (event.clock) {
      tempClock = {
        ...tempClock,
        displayTime: convertClock(event.clock),
      };
    }
  });

  if (messagesToProcess.length > 0) {
    updateState("periodCatchup", tempPeriod);
    updateState("clockCatchup", tempClock);
  }

  lastProcessedEventId = message[message.length - 1].eventId;
}
