import { injectIntl } from "react-intl";

const HandballClockButtons = (props) => {
  const {
    clockState,
    intl,
    startButtonPressed,
    stopButtonPressed,
    onPitchButtonPressed,
    endOnPitchButtonPressed,
    warmUpButtonPressed,
    endWarmUpButtonPressed,
    throwOffButtonPressed,
    startMatchButtonPressed,
    endPeriodButtonPressed,
    confirmPeriodButtonPressed,
    startPeriodButtonPressed,
    nextExtraPeriodButtonPressed,
    shootOutButtonPressed,
    endMatchButtonPressed,
    confirmFixtureButtonPressed,
  } = props;
  const { formatMessage } = intl;

  return (
    <>
      {clockState === "stopped" && (
        <span className="hb-start-button" onClick={startButtonPressed}>
          {formatMessage({
            id: "clock.start",
            defaultMessage: "Start",
          })}
        </span>
      )}
      {clockState === "running" && (
        <span className="hb-stop-button" onClick={stopButtonPressed}>
          {formatMessage({
            id: "clock.stop",
            defaultMessage: "Stop",
          })}
        </span>
      )}
      {clockState === "waitingForOnPitch" && (
        <span className="hb-start-button" onClick={onPitchButtonPressed}>
          {formatMessage({
            id: "clock.playersOnCourt",
            defaultMessage: "Players On Court",
          })}
        </span>
      )}
      {clockState === "waitingForEndOnPitch" && (
        <span className="hb-stop-button" onClick={endOnPitchButtonPressed}>
          {formatMessage({
            id: "clock.playersOnCourt.end",
            defaultMessage: "End Players On Court",
          })}
        </span>
      )}
      {clockState === "waitingForWarmUp" && (
        <span className="hb-start-button" onClick={warmUpButtonPressed}>
          {formatMessage({
            id: "clock.playersWarmingUp",
            defaultMessage: "Players Warming Up",
          })}
        </span>
      )}
      {clockState === "waitingForEndWarmUp" && (
        <span className="hb-stop-button" onClick={endWarmUpButtonPressed}>
          {formatMessage({
            id: "clock.playersWarmingUp.end",
            defaultMessage: "End Players Warming Up",
          })}
        </span>
      )}
      {clockState === "waitingForThrowOff" && (
        <span className="hb-start-button" onClick={throwOffButtonPressed}>
          {formatMessage({
            id: "clock.throwOff",
            defaultMessage: "Throw off",
          })}
        </span>
      )}
      {clockState === "waitingForStartMatch" && (
        <span className="hb-start-button" onClick={startMatchButtonPressed}>
          {formatMessage({
            id: "clock.start.fixture",
            defaultMessage: "Start Match",
          })}
        </span>
      )}
      {clockState === "waitingForPeriodEnd" && (
        <span className="hb-stop-button" onClick={endPeriodButtonPressed}>
          {formatMessage({
            id: "clock.end.period",
            defaultMessage: "End Period",
          })}
        </span>
      )}
      {clockState === "waitingForPeriodConfirm" && (
        <span className="hb-stop-button" onClick={confirmPeriodButtonPressed}>
          {formatMessage({
            id: "clock.confirm.period",
            defaultMessage: "Confirm Period",
          })}
        </span>
      )}
      {clockState === "waitingForPeriodStart" && (
        <span className="hb-start-button" onClick={startPeriodButtonPressed}>
          {formatMessage({
            id: "clock.start.period",
            defaultMessage: "Start Period",
          })}
        </span>
      )}
      {["waitingForExtraPeriodStart", "shootOutInProgress"].includes(clockState) && (
        <span className="hb-next-button hb-multi-button" onClick={nextExtraPeriodButtonPressed}>
          {formatMessage({
            id: "clock.continue.period",
            defaultMessage: "Next Period",
          })}
        </span>
      )}
      {["waitingForExtraPeriodStart", "shootOutInProgress"].includes(clockState) && (
        <span className="hb-next-button hb-multi-button" onClick={shootOutButtonPressed}>
          {formatMessage({
            id: "clock.shootout",
            defaultMessage: "Shoot-out",
          })}
        </span>
      )}
      {["waitingForExtraPeriodStart", "shootOutInProgress"].includes(clockState) && (
        <span className="hb-stop-button hb-multi-button" onClick={endMatchButtonPressed}>
          {formatMessage({
            id: "clock.end.fixture",
            defaultMessage: "End Match",
          })}
        </span>
      )}
      {clockState === "waitingForFixtureEnd" && (
        <span className="hb-stop-button" onClick={confirmFixtureButtonPressed}>
          {formatMessage({
            id: "clock.confirm.fixture",
            defaultMessage: "Confirm Game",
          })}
        </span>
      )}
      {clockState === "fixtureComplete" && (
        <span className="hb-done-button" onClick={confirmFixtureButtonPressed}>
          {formatMessage({
            id: "clock.complete.fixture",
            defaultMessage: "Complete",
          })}
        </span>
      )}
    </>
  );
};

export default injectIntl(HandballClockButtons);
