const ClockAdjustSegment = (props) => {
  const { onUpPressed, onDownPressed } = props;
  return (
    <div className="hbclock-adjust-segment">
      <i className="up fas fa-caret-square-up" onClick={onUpPressed}></i>
      <i className="down fas fa-caret-square-down" onClick={onDownPressed}></i>
    </div>
  );
};

export default ClockAdjustSegment;
