import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { v1 as uuidv1 } from "uuid";
import { injectIntl } from "react-intl";
import { lightOrDark } from "../../Base/Utilities/LightOrDark";
import { useIsVisible } from "../../Base/Hooks/IsVisible";
import { useDoActions } from "../../Base/Hooks/doActions";
import { getEventTime, sendEvent } from "../../Base/Utilities/SendEvent";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails.jsx";

const ChangeGoalkeeper = (props) => {
  const { panel, selectedPlayer, currentState, updateState } = props;
  const { entities, events } = currentState;
  const [doAction, setDoAction] = useState(false);
  const [actionsDone] = useDoActions(panel, props, doAction);
  const [isVisible, isEnabled] = useIsVisible(panel, props);

  const activeRef = useRef(null);
  const currentEntityEvent = panel.options.currentEntityEvent ?? "main";
  const { sport } = useContext(FixtureDetailsContext);

  useEffect(() => {
    if (actionsDone) {
      setDoAction(false);
    }
  }, [actionsDone, props]);

  const effectiveEntity = useMemo(() => {
    const { currentEntity } = panel.options;
    return entities.find((entity) => {
      if (currentEntity) {
        return entity.entityId === events[currentEntityEvent].entityId;
      }

      return entity.entityId !== events[currentEntityEvent].entityId;
    });
  }, [panel.options, entities, events]);

  const goalkeepers = useMemo(() => {
    const persons =
      JSON.parse(JSON.stringify(
        effectiveEntity?.persons.filter((person) => person.position === "G")
      ));

    if (panel.options.goalKeeperUpdate) {
      persons.map(person => {
        person.active = true;
        return person;
      });
    }
    return persons;
  }, [effectiveEntity]);

  const sendGoalKeeperChangeEvent = (personId, entity) => {
    const timestamp = new Date();
    const clockParts = currentState.clock.displayTime.split(":");
    const msg = {
      class: "sport",
      eventType: "goalKeeperChange",
      entityId: entity.entityId,
      personId: personId,
      eventId: uuidv1(),
      playId: uuidv1(),
      periodId: currentState.period.periodId,
      timestamp: timestamp.toISOString(),
      eventTime: getEventTime(),
      clock:
        "PT" + parseInt(clockParts[0]) + "M" + parseInt(clockParts[1]) + "S",
    };

    const subAction = {
      action: "sendEvent",
      event: msg,
      type: "custom",
    };

    sendEvent(subAction, props);
  };

  const onPersonSelect = (selectedGoalkeeper) => {
    if (panel.options?.sendGoalKeeperChangeEvent && !selectedGoalkeeper.active) {
      sendGoalKeeperChangeEvent(selectedGoalkeeper.personId, effectiveEntity)
    }

    if (!panel.options?.selectOnly && !selectedGoalkeeper.active) {
      const playId = uuidv1();
      goalkeepers.forEach((person) => {
        if (person.active) {
          togglePlayerActive(person, playId);
        }
      });

      togglePlayerActive(selectedGoalkeeper, playId);
    }

    if (panel.options?.selectOnly && !panel.options.goalKeeperUpdate) {
      playerSelect(selectedGoalkeeper);
    }

    if (panel.options.goalKeeperUpdate) {
      events[currentEntityEvent].options.goalKeeperId = selectedGoalkeeper.personId
      const action = {
        action: "sendEvent",
        event: events[currentEntityEvent],
        type: "custom",
      };

      sendEvent(action, props);
    }

    setDoAction(true);
  };

  const onOpenNetClick = () => {
    const tempInsertStore = props.currentState.insertStore.map(insertEvent => {
      if (insertEvent.eventId === events.main.eventId) {
        delete insertEvent.options.goalKeeperId;
        insertEvent.options.emptyNet = true;
      }
      return insertEvent
    });

    updateState("insertStore", tempInsertStore);

    setDoAction(true);
  }

  function playerSelect(player) {
    if (!props.update) {
      let tempEvents = props.currentState.events;
      tempEvents["main"]["personId"] = player.personId;
      if (!tempEvents["main"]["flagged"]) {
        tempEvents["main"]["flagged"] = !player.active &&
          !panel.options.ignoreFlag;
      }

      if (panel.options?.addGoalkeeperIdToEvent) {
        const goalEvent = panel.options?.addGoalkeeperIdToEvent;
        tempEvents[goalEvent]["options"]["goalKeeperId"] = player.personId;
      }

      tempEvents["main"]["entityId"] = effectiveEntity.entityId;
      updateState("events", tempEvents);
      setDoAction(true);
    } else {
      props.update(player, effectiveEntity.entityId);
    }
  }

  const togglePlayerActive = (person, playId) => {
    const timestamp = new Date();
    const clockParts = currentState.clock.displayTime.split(":");
    const msg = {
      class: "sport",
      eventType: "substitution",
      subType: person.active ? "out" : "in",
      entityId: effectiveEntity.entityId,
      personId: person.personId,
      eventId: uuidv1(),
      playId,
      periodId: currentState.period.periodId,
      timestamp: timestamp.toISOString(),
      eventTime: getEventTime(),
      clock:
        "PT" + parseInt(clockParts[0]) + "M" + parseInt(clockParts[1]) + "S",
    };

    const subAction = {
      action: "sendEvent",
      event: msg,
      type: "custom",
    };
    sendEvent(subAction, props);
  };

  return (
    <React.Fragment>
      {isVisible && (
        <div
          className={panel.layout + " enabled-" + isEnabled}
          style={props.containerStyle}
        >
          <div className="active-players" ref={activeRef}>
            {goalkeepers.map((player) => {
              const isPlayerSelected =
                (player.personId === selectedPlayer ||
                  player.personId ===
                  props.currentState.events["main"].personId ||
                  (panel.options.goalKeeperUpdate
                    && events.main.options?.goalKeeperId === player.personId
                    && !currentState?.insertMode?.enabled
                  )
                ) &&
                panel.options?.noSelectedOptionOnStart !== true;
              const selectedStyling = isPlayerSelected ? "selected" : "";
              return (
                <div
                  className={`player active-${player.active || panel.options.alwaysActive} ${lightOrDark(
                    effectiveEntity.primaryColor,
                  )} ${selectedStyling}`}
                  key={player.personId}
                  style={Object.assign(
                    player.personId === selectedPlayer
                      ? {
                        borderColor: effectiveEntity.primaryColor,
                        backgroundColor: "#ffffff",
                        color: "#202020",
                      }
                      : {
                        borderColor: effectiveEntity.primaryColor,
                        backgroundColor: effectiveEntity.primaryColor,
                      },
                    props.playerStyle ?? {},
                  )}
                  onClick={() => onPersonSelect(player)}
                >
                  {player.bib}
                </div>
              );
            })}
          </div>
        </div>
      )}

      {currentState?.insertMode?.enabled && sport === "handball" && (
        <div className="button-group centered enabled-true">
          <div className="type-button" onClick={() => onOpenNetClick()}>Open
            Net
          </div>
        </div>
      )}

    </React.Fragment>
  );
};

export default injectIntl(ChangeGoalkeeper);
