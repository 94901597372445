import { convertClock } from "./ConvertClock";
import Logger from "./Logger";

const logger = Logger.getInstance();

const CARD_EVENT_TYPES = ["greenCard", "yellowCard", "redCard", "blueCard"];

function getSuspensionEventsForEntity(entity, events) {
  return events.filter((event) => event.entityId === entity.entityId && event.eventType === "suspension");
}

function getCardEventsForEntity(entity, events) {
  return events.filter((event) => event.entityId === entity.entityId && CARD_EVENT_TYPES.includes(event.eventType));
}

function getCardEventByType(type, events) {
  return events.find((event) => event.class === "sport" && event.eventType === type);
}

function getSuspensionTime(event, fixtureProfile) {
  if (event.eventType === "suspension") {
    if (event.subType === "twoMinutes") return 2 * 60;
    if (event.subType === "fourMinutes") return 4 * 60;
  }

  if (event.eventType === "greenCard") {
    return fixtureProfile.greenCardSeconds;
  }

  if (!event.options?.time) {
    // TODO: not sure how this happened, but already saw it a couple of times
    logger.log("Suspension event without time:", event);
  }

  return parseInt(event.options.time, 10) * 60;
}

function calculateSuspensionTimeInSamePeriod(event, displayTime, fixtureProfile) {
  const convertedEventTime = convertClock(event.clock);
  const [eventMinutes, eventSeconds] = convertedEventTime.split(":").map((part) => parseInt(part, 10));
  const [clockMinutes, clockSeconds] = displayTime.split(":").map((part) => parseInt(part, 10));

  const suspensionTime = getSuspensionTime(event, fixtureProfile);

  if (fixtureProfile.clockDirection === "COUNT_UP") {
    return suspensionTime - (clockMinutes * 60 + clockSeconds - (eventMinutes * 60 + eventSeconds));
  }

  return suspensionTime - (eventMinutes * 60 + eventSeconds - (clockMinutes * 60 + clockSeconds));
}

function getRemainingTimeFromPreviousPeriod(event, fixtureProfile) {
  const convertedEventTime = convertClock(event.clock);
  const [eventMinutes, eventSeconds] = convertedEventTime.split(":").map((part) => parseInt(part, 10));
  const suspensionTime = getSuspensionTime(event, fixtureProfile);

  if (fixtureProfile.clockDirection === "COUNT_UP") {
    return suspensionTime - (fixtureProfile.periodLength * 60 - (eventMinutes * 60 + eventSeconds));
  }

  return suspensionTime - (eventMinutes * 60 + eventSeconds);
}

function calculateSuspensionTimeOverlapPeriods(event, displayTime, fixtureProfile) {
  const remainingTimeFromPreviousPeriod = getRemainingTimeFromPreviousPeriod(event, fixtureProfile);

  if (remainingTimeFromPreviousPeriod <= 0) {
    return 0;
  }

  const [clockMinutes, clockSeconds] = displayTime.split(":").map((part) => parseInt(part, 10));

  const periodLength = fixtureProfile.periodLength * 60;

  const elapsedTimeInThisPeriod =
    fixtureProfile.clockDirection === "COUNT_UP"
      ? clockMinutes * 60 + clockSeconds
      : periodLength - (clockMinutes * 60 + clockSeconds);

  return remainingTimeFromPreviousPeriod - elapsedTimeInThisPeriod;
}

export function getRemainingTimeFromSuspension(event, displayTime, periodId, fixtureProfile) {
  if (!event || !displayTime) {
    return -1;
  }

  if (periodId === event.periodId) {
    return calculateSuspensionTimeInSamePeriod(event, displayTime, fixtureProfile);
  }

  if (periodId - 1 === event.periodId) {
    return calculateSuspensionTimeOverlapPeriods(event, displayTime, fixtureProfile);
  }

  return 0;
}

function getActiveCardEventByType(type, events, displayTime, periodId, fixtureProfile) {
  if (!displayTime) {
    return null;
  }

  return events
    .filter((event) => event.class === "sport" && event.eventType === type)
    .map((event) => {
      const remainingTime = getRemainingTimeFromSuspension(event, displayTime, periodId, fixtureProfile);

      return {
        ...event,
        remainingTime,
      };
    })
    .find((event) => event.remainingTime > 0);
}

export function getCardsForPerson({ entity, events, personId }) {
  const cardEvents = getCardEventsForEntity(entity, events);
  const cardEventsForPlayer = cardEvents.filter((event) => event.personId === personId);

  const blueCard = getCardEventByType("blueCard", cardEventsForPlayer);
  if (blueCard) {
    return blueCard;
  }

  const redCard = getCardEventByType("redCard", cardEventsForPlayer);
  if (redCard) {
    return redCard;
  }

  const yellowCard = getCardEventByType("yellowCard", cardEventsForPlayer);
  if (yellowCard) {
    return yellowCard;
  }
}

export function getActiveSuspensionForPerson({ displayTime, entity, events, fixtureProfile, periodId, personId }) {
  if (!displayTime) {
    return null;
  }

  const suspensionEvents = events.filter(
    (event) =>
      event.eventType === "suspension" &&
      event.class === "sport" &&
      event.entityId === entity.entityId &&
      event.personId === personId,
  );

  if (!suspensionEvents) {
    return null;
  }

  return suspensionEvents
    .map((event) => {
      const remainingTime = getRemainingTimeFromSuspension(event, displayTime, periodId, fixtureProfile);

      return {
        ...event,
        remainingTime,
      };
    })
    .find((event) => event.remainingTime > 0);
}

export function getLatestSuspensionEventForPersonId({ personId, entity, events }) {
  const suspensions = getSuspensionEventsForEntity(entity, events);
  const suspensionEventForPlayer = suspensions.filter(
    (event) => event.personId === personId && event.class === "sport",
  );

  return suspensionEventForPlayer.reduce((latest, curr) => {
    if (!latest) {
      return curr;
    }

    if (new Date(latest?.eventTime).getTime() > new Date(curr.eventTime).getTime()) {
      return latest;
    }

    return curr;
  }, null);
}

export function getLastCardEventForPersonId({ personId, entity, events }) {
  const cardEvents = getCardEventsForEntity(entity, events);
  const cardEventsForPlayer = cardEvents.filter((event) => event.personId === personId && event.class === "sport");

  return cardEventsForPlayer.reduce((latest, curr) => {
    if (!latest) {
      return curr;
    }

    if (new Date(latest?.eventTime).getTime() > new Date(curr.eventTime).getTime()) {
      return latest;
    }

    return curr;
  }, null);
}

export function getRelatedClockEventsByPlayId({ playId, events }) {
  if (!playId) {
    return;
  }

  return {
    adjust: events.find((event) => event.playId === playId && event.subType === "adjust"),
    start: events.find((event) => event.playId === playId && event.subType === "start"),
    stop: events.find((event) => event.playId === playId && event.subType === "stop"),
  };
}

export function getCardOrSuspensionByPersonId({ personId, entity, events, displayTime, periodId, fixtureProfile }) {
  const cardEvents = getCardEventsForEntity(entity, events);
  const cardEventsForPlayer = cardEvents.filter((event) => event.personId === personId);

  const redCard = getCardEventByType("redCard", cardEventsForPlayer);

  if (redCard) {
    return redCard;
  }

  const yellowCard = getActiveCardEventByType("yellowCard", cardEventsForPlayer, displayTime, periodId, fixtureProfile);

  if (yellowCard) {
    return yellowCard;
  }

  const greenCard = getActiveCardEventByType("greenCard", cardEventsForPlayer, displayTime, periodId, fixtureProfile);

  if (greenCard) {
    return greenCard;
  }

  return null;
}

export function getPlayerFromSinBin(applicationState, eventStore, fixtureProfile) {
  const { entities, clock, period } = applicationState;

  return entities.map((entity) => {
    return entity.persons.filter((person) => {
      const activeCardEvent = getCardOrSuspensionByPersonId({
        personId: person.personId,
        entity,
        events: eventStore,
        displayTime: clock?.displayTime,
        periodId: period?.periodId,
        fixtureProfile,
      });

      return !!activeCardEvent;
    }, 0);
  });
}

export function getPlayersWithActiveSuspensions(applicationState, eventStore, fixtureProfile) {
  const { entities, clock, period } = applicationState;
  const displayTime = clock?.displayTime;
  const periodId = period?.periodId;
  return entities.map((entity) => {
    if (!displayTime) {
      return [];
    }
    const suspensionEvents = eventStore.filter(
      (event) =>
        event.eventType === "suspension" &&
        !event.options?.possible &&
        event.entityId === entity.entityId &&
        event.class === "sport",
    );
    if (!suspensionEvents) {
      return [];
    }
    const remainingSuspensions = suspensionEvents
      .map((event) => {
        const remainingTime = getRemainingTimeFromSuspension(event, displayTime, periodId, fixtureProfile);
        return {
          ...event,
          remainingTime,
        };
      })
      .filter((event) => event.remainingTime > 0);
    return remainingSuspensions;
  });
}
