import { useContext, useEffect, useState } from "react";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";
import {
  isPowerPlayActive,
  isPowerPlayEnabled,
} from "../Utilities/MatchProfile";

export const useIsVisible = (panel, props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const { fixtureProfile, season } = useContext(FixtureDetailsContext);

  useEffect(() => {
    let state = props.currentState;
    if (panel.showOn) {
      let showArray = [];
      if (
        props.currentState.insertMode &&
        props.currentState.insertMode.enabled === true &&
        panel.showForInsert
      ) {
        setIsVisible(true);
      } else {
        panel.showOn.map((rule) => {
          if (rule === true) {
            setIsVisible(true);
          } else if (rule === false) {
            showArray.push(false);
          } else {
            let conditions = rule.state.split(".");
            if (conditions.length > 1) {
              if (conditions[0] === "entity") {
                let currentEntity = state.entities.find(
                  (el) => el.entityId === state.events["main"].entityId
                );
                showArray.push(
                  currentEntity[conditions[1]] === rule.value ? true : false
                );
              } else if (conditions[0] === "event") {
                switch (rule.operator) {
                  case "is":
                    if (
                      typeof state.events[rule.event][conditions[1]] ===
                        "undefined" &&
                      rule.value === null
                    ) {
                      showArray.push(true);
                    } else {
                      if (Array.isArray(rule.value)) {
                        showArray.push(
                          rule.value.includes(
                            state.events[rule.event][conditions[1]]
                          )
                        );
                      } else {
                        if (rule.value === false || rule.value === true) {
                          showArray.push(
                            !!state.events[rule.event][conditions[1]] ===
                              rule.value
                          );
                        } else {
                          showArray.push(
                            state.events[rule.event][conditions[1]] ===
                              rule.value
                              ? true
                              : false
                          );
                        }
                      }
                    }
                    break;
                  case "not":
                    if (Array.isArray(rule.value)) {
                      showArray.push(
                        !rule.value.includes(
                          state.events[rule.event][conditions[1]]
                        )
                      );
                    } else {
                      showArray.push(
                        state.events[rule.event][conditions[1]] &&
                          state.events[rule.event][conditions[1]] !== rule.value
                          ? true
                          : false
                      );
                    }
                    break;
                  default:
                    break;
                }
              } else if (conditions[0] === "eventOptions") {
                const eventId = rule.event ?? "main";
                const conditionValue =
                  state.events[eventId]?.options[conditions[1]];
                if (rule.operator === "is") {
                  const checkValue =
                    (typeof conditionValue === "undefined" &&
                      rule.value === null) ||
                    conditionValue === rule.value;
                  showArray.push(checkValue);
                } else if (rule.operator === "not") {
                  showArray.push(conditionValue !== rule.value);
                }
              } else if (conditions[0] === "fixture") {
                if (conditions[1] === "isPowerPlayEnabled") {
                  const isPPEnabled = isPowerPlayEnabled(state, fixtureProfile);
                  showArray.push(
                    rule.operator === "is" ? isPPEnabled : !isPPEnabled
                  );
                } else if (conditions[1] === "isPowerPlayActive") {
                  const isPPActive = isPowerPlayActive(
                    state,
                    fixtureProfile,
                    props.eventStore
                  );
                  showArray.push(
                    rule.operator === "is" ? isPPActive : !isPPActive
                  );
                } else {
                  console.warn("Unknown fixture profile condition check.");
                }
              } else if (conditions[0] === "fixtureProfile") {
                if (rule.operator === "is") {
                  showArray.push(fixtureProfile[conditions[1]] === rule.value);
                } else {
                  showArray.push(fixtureProfile[conditions[1]] !== rule.value);
                }
              } else if (conditions[0] === "seasonDetails") {
                if (rule.operator === "is") {
                  showArray.push(season[conditions[1]] === rule.value);
                } else {
                  showArray.push(season[conditions[1]] !== rule.value);
                }
              } else if (conditions[0] === "insertMode") {
                const isEnabled = state?.insertMode?.enabled === true;
                rule.operator === "is"
                  ? showArray.push(isEnabled === rule.value)
                  : showArray(isEnabled !== rule.value);
              } else if (conditions[0] === 'videoReview') {

                const eventStore = [...props.eventStore].sort(
                    (a, b) => new Date(a.eventTime) - new Date(b.eventTime)
                );

                if (state?.insertMode?.enabled === true) {
                  const insertPlayIndex = eventStore.findIndex(
                      item => item.playId === state.insertMode.play.playId
                  );
                  if (insertPlayIndex !== -1) {
                    eventStore.splice(insertPlayIndex + 1);
                  }
                }

                const reviewEvents = eventStore.filter(
                    e => e.eventType === "videoReview"
                );
                const lastElement = reviewEvents[reviewEvents.length - 1];
                const reviewIsStarted = lastElement?.subType === "start"
                showArray.push(
                    rule.value === true ? reviewIsStarted : !reviewIsStarted
                );

              } else {
                if (typeof state[conditions[0]] === "undefined") {
                  showArray.push(false);
                } else {
                  if (
                    typeof state[conditions[0]][conditions[1]] === "undefined"
                  ) {
                    showArray.push(false);
                  } else {
                    switch (rule.operator) {
                      case "is":
                        showArray.push(
                          state[conditions[0]][conditions[1]] === rule.value
                            ? true
                            : false
                        );
                        break;
                      case "not":
                        showArray.push(
                          state[conditions[0]][conditions[1]] !== rule.value
                            ? true
                            : false
                        );
                        break;
                      default:
                        break;
                    }
                  }
                }
              }
            } else {
              if (typeof state[conditions[0]] === "undefined") {
                showArray.push(false);
              } else {
                switch (rule.operator) {
                  case "is":
                    showArray.push(
                      state[rule.state] === rule.value ? true : false
                    );
                    break;
                  case "not":
                    showArray.push(
                      state[rule.state] !== rule.value ? true : false
                    );
                    break;
                  default:
                    break;
                }
              }
            }
          }
          return false;
        });
      }
      if (panel.mode === "visible") {
        setIsVisible(showArray.includes(false) ? false : true);
        setIsEnabled(true);
      } else {
        setIsEnabled(showArray.includes(false) ? false : true);
        setIsVisible(true);
      }
    }

    // eslint-disable-next-line
  }, [panel, props]);

  return [isVisible, isEnabled];
};
