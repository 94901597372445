const technicalFaultPlayerSelectActions = [
  {
    actions: [
      { action: "sendEvent", value: "edit", type: "main" },
      {
        action: "setPossession",
        value: { currentEntity: false },
        type: "main",
      },
    ],
    conditions: [{ item: "subType", value: "steal", event: "main", operator: "not" }],
  },
  {
    actions: [
      { action: "nextState", value: null, type: "primary" },

      { action: "processInserts", value: "all", type: "inserts" },
    ],
    conditions: [
      { item: "subType", value: "steal", event: "main", operator: "not" },
      { item: "emptyProperty", value: "subType", event: "main" },
    ],
    operator: "any",
  },
  {
    actions: [
      { action: "clearData", value: ["subType"], type: "main" },
      { action: "sendEvent", value: "edit", type: "main" },
      { action: "processInserts", value: "all", type: "inserts" },
      { action: "nextState", value: null, type: "primary" },
    ],
    conditions: [{ item: "subType", value: "steal", event: "main", operator: "is" }],
  },
];

export const TechnicalFaultPlayer = {
  name: "technical-fault-player",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "technicalFault.select-player",
  showOn: [{ state: "primary", value: "technical-fault-player", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  allowBack: {
    state: "primary",
    value: "steal-player",
    event: "main",
  },
  children: [
    {
      name: "technical-fault-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: false,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: true,
        showBench: false,
        showCoach: false,
        immutableDateTime: true,
      },
      actionGroups: technicalFaultPlayerSelectActions,
    },
    {
      name: "confirm-later-technical-fault-player",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: "confirmLater",
      showOn: [true],
      options: {
        immutableDateTime: true,
      },
      actionGroups: [
        {
          actions: [
            { action: "saveData", type: "flagged", value: true },

            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [],
        },
        ...technicalFaultPlayerSelectActions,
      ],
      startEvent: false,
      startPlay: false,
    },
  ],
};
