export const FloatingPanel = {
  name: "main-buttons",
  component: "TeamButtons",
  layout: "team-buttons-base",
  mode: "visible",
  showOn: [
    { state: "primary", value: "main-buttons", operator: "is" },
    { state: "region", value: "OUT", operator: "not" },
  ],
  startEvent: false,
  startPlay: false,
  hideIfNoPossession: true,
  children: [
    {
      name: "goal-button",
      component: "MadeMissedHandball",
      layout: "team-button",
      mode: "enable",
      text: "goal",
      showForInsert: true,
      showOn: [
        { state: "period.periodStatus", value: "fixtureConfirm", operator: "not" },
        { state: "period.periodStatus", value: "fixtureComplete", operator: "not" },
      ],
      options: { send: "new", success: 1, changePossession: true },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "goal", type: "eventType" },
            {
              action: "saveData",
              value: ["periodId", "clock", "eventTime", "x", "y"],
              type: "variables",
            },
            { action: "nextState", value: "shot-attack-type", type: "primary" },
          ],
          conditions: [],
        },
      ],
      startEvent: true,
      startPlay: true,
    },
    {
      name: "shot-button",
      component: "MadeMissedHandball",
      layout: "team-button",
      mode: "enable",
      text: "shot.missed",
      showForInsert: true,
      showOn: [
        { state: "period.periodStatus", value: "fixtureConfirm", operator: "not" },
        { state: "period.periodStatus", value: "fixtureComplete", operator: "not" },
      ],
      options: { success: 0 },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "goal", type: "eventType" },
            {
              action: "saveData",
              value: ["periodId", "clock", "eventTime", "x", "y"],
              type: "variables",
            },
            {
              action: "nextState",
              value: "shot-missed-outcome-type",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
      startEvent: true,
      startPlay: true,
    },
    {
      name: "tech-fault-button",
      component: "TypeButton",
      layout: "team-button",
      mode: "enable",
      text: "technicalFault",
      showForInsert: true,
      showOn: [],
      options: { immutableDateTime: true },
      actionGroups: [
        {
          actions: [
            { action: "clearEvent", value: "fault" },
            { action: "clearEvent", value: "steal" },
            { action: "saveData", value: "technicalFault", type: "eventType" },
            {
              action: "saveData",
              value: ["periodId", "clock", "x", "y"],
              type: "variables",
            },
            { action: "clearData", value: ["subType"], type: "main" },
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "nextState",
              value: "technical-fault-type",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
      startEvent: true,
      startPlay: true,
    },
  ],
  actionGroups: [
    {
      actions: [{ action: "nextState", value: null, type: "primary" }],
    },
  ],
};
